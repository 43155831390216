import React from "react";

const VBox = ({ gap=20, width='fit-content', children, top=0, style, center }) => {

    return (
        <div style={{display: "flex", flexDirection: 'column', gap: gap, width: width, marginTop: top, alignItems: center ? 'center' : 'flex-start', ...style}}>
            { children }
        </div>
    )
}

export default VBox;
