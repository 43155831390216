import React from 'react';
import './circle-progress.scss';

export class CircleProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startPercentage: 0,
            displayedProgress: 0,
        };
    }
    componentDidMount() {
        this.interval = this.props.duration / 1000;
        this.increment();
    }
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }
    easeOutQuad = t => t * (2 - t);
    increment() {
        if (this.state.startPercentage < this.props.percentage) {
            this.timeout = setTimeout(() => {
                let { startPercentage } = this.state;
                this.setState({
                    startPercentage: startPercentage += 1,
                    displayedProgress: Math.round(startPercentage - 1)
                });
                this.increment();
            }, this.interval);
        } else if (this.state.startPercentage >= this.props.percentage) {
            clearTimeout(this.timeout);
        }
    }
    render() {
        const { displayedProgress } = this.state;
        const r = 34;
        const c = Math.PI * (r * 2);
        const pct = (100 - displayedProgress) / 100 * c;
        return (
            <div className={`${this.props.className ? this.props.className : ''}`}>
                <div className="circle-progress" data-pct={this.props.percentage}>
                    <svg className="circle-progress-content" viewBox="0 0 74 74">
                        <circle
                            className="bg"
                            cx="37"
                            cy="37"
                            r="34"
                            fill="none"
                            strokeWidth={this.props.strokeWidth || "3"}
                            strokeDasharray="213.63"
                            strokeDashoffset={0}
                        />
                        {!this.props.bar &&
                            <circle
                                className="bar"
                                cx="37"
                                cy="37"
                                r="34"
                                fill="none"
                                strokeWidth={this.props.strokeWidth || "3"}
                                strokeDasharray="213.63"
                                strokeDashoffset={pct}
                            />
                        }
                    </svg>
                </div>
            </div>
        );
    }
}
