import React from 'react';
import styles from './article.module.scss';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {getTextDate} from "../../helper/dates";


export const Article = ({heading = '', articles, basePath = '', mini=false, wording}) => {

    return (
        <div className={`${styles.article}`}>
            {heading && <h3 className={styles.heading}>{heading}</h3>}
            <div>
                {articles.map(article => {
                    const {title, id, heading, remaining, address, image, date} = article;

                    return <div key={id} style={{marginBottom: 20}}>
                        {!!image && !mini &&
                            <Link to={`/${basePath}${basePath ? '/' : ''}${id}`}>
                                <img src={image} alt="event" style={{objectFit: 'cover'}}/>
                            </Link>
                        }
                        <h4>
                            <Link to={`/${basePath}${basePath ? '/' : ''}${id}`}>{title || heading}</Link>
                        </h4>
                        {date && <div className={styles.info}>
                            {getTextDate(date, true)}, {new Date(date).getFullYear()}
                            {address && <span>
                                <FontAwesomeIcon icon={faLocationDot} />
                                {address}
                            </span>}
                        </div>}
                        {remaining >= 0 && <div className={styles.info}>{remaining} {wording['left-heading']}</div>}
                    </div>
                })}
            </div>
        </div>
    )
}
