export const owlCarouselNavReset = () => {
    setTimeout(() => {
        const owlCarousel = document.getElementsByClassName('owl-loaded');

        [].forEach.call(owlCarousel,(currentCarousel) => {
            if (currentCarousel) {
                const owlNav = currentCarousel.querySelector('div.owl-nav');
                const owlDots = currentCarousel.querySelector('div.owl-dots');
                if (owlNav && owlDots) {
                    const owlControlsContainer = document.createElement('div');
                    const owlControls = document.createElement('div');
                    owlControlsContainer.className = 'owl-controls-container';
                    owlControls.className = 'owl-controls';

                    owlControlsContainer.appendChild(owlControls);
                    owlControls.appendChild(owlNav);
                    owlControls.appendChild(owlDots);
                    currentCarousel.appendChild(owlControlsContainer);
                }
            }
        });
    }, 200);
}