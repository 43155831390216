import React from 'react';
import styles from './event.module.scss';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {EventDate} from "../event-date/event-date";
import ReactMarkdown from "react-markdown";

export const Event = ({
    eventData,
    eventLink='event',
    className = ''
}) => {
    const {
        id,
        image,
        heading,
        description,
        date,
        time
    } = eventData;

    return (
        <div className={`${styles.event} ${className}`}>
            <div className={styles.eventDetails}>
                {date && <EventDate eventDate={date} className={styles.eventDate} />}
                <h3>
                    <Link to={`/${eventLink}/${id}`}>
                        {heading}
                    </Link>
                </h3>
                {time && <ul className={styles.eventInfo}>
                    <li>
                        <FontAwesomeIcon icon={faClock}/>
                        {time}
                    </li>
                </ul>}
                <p className={styles.text}><ReactMarkdown className={styles.preLine}>{description}</ReactMarkdown></p>
                <Link to={`/${eventLink}/${id}`} className={styles.joinLink}>
                    глядзець
                    <FontAwesomeIcon icon={faAngleRight}/>
                </Link>
            </div>
            <Link to={`/${eventLink}/${id}`}>
                <div className={styles.image}>
                    <img src={image} alt={heading}/>
                </div>
            </Link>
        </div>
    )
}
