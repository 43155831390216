import React from 'react';
import styles from './tabs.module.scss';
import './tabs.scss';
import {EventTab} from "../event-tab/event-tab";
import {ActionButton} from "../action-button/action-button";
import {Link} from "react-router-dom";

export const CHTabs = ({ events, wording }) => {

    const getEvents = () => {
        if (!events.length) return null;
        return events.map((topEvent, index) => (
            <EventTab {...topEvent} key={index} />
        ))
    }

    return (
        <div className={`${styles.tabs} ch_tabs`}>
            {getEvents()}
            <div className={styles.moreEvents}>
                <Link to={'/events'}><ActionButton>{wording['see-more-heading']}</ActionButton></Link>
            </div>
        </div>
    )
}
