import React from 'react';
import styles from './cause-carousel.module.scss';
import './cause-carousel.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {CauseCard} from "../cause-card/cause-card";
import {owlCarouselNavReset} from "../../helper/owlCarouselNavReset";

export const CauseCarousel = ({causes, wording}) => {
    owlCarouselNavReset();

    const getCauses = () => (
        causes.map(cause => <CauseCard wording={wording} cause={cause} key={cause.id} />)
    )

    const responsive = {
        0: {
            items: 1
        },
        500: {
            items: 2
        },
        1000: {
            items: 3
        }
    }

    return (
        <div className={`${styles.carousel} cause-carousel`}>
            <OwlCarousel className='owl-theme' loop margin={10} nav responsive={responsive}>
                {getCauses()}
            </OwlCarousel>
        </div>
    )
}
