import React, {useState} from 'react';
import styles from './events.module.scss';
import {ContentInfo} from "../../components/content-info/content-info";
import {Event} from "../../components/event/event";
import {ActionButton} from "../../components/action-button/action-button";
import {connect} from "react-redux";

const Events = ({ events, projects }) => {
    const [showedItem, setShowedItem] = useState(3);


    const getEvents = () => {
        if (!events.length) return null;
        return events.map((currentEvent, index) => {
            if (index >= showedItem) {
                return null;
            }
            return <Event eventData={currentEvent} key={currentEvent.id} />;
        })
    };

    const loadMore = () => {
        setShowedItem(events.length);
    }

    return (
        <div className={`${styles.eventInfoContainer} event-info`}>
            <ContentInfo asideProjects={projects}>
                {getEvents()}
                {showedItem < events.length &&
                    <div className={styles.actionButtonContainer}>
                        <ActionButton icon={false} onClick={loadMore}>Глядзець далей</ActionButton>
                    </div>
                }
            </ContentInfo>
        </div>
    )
}

const mapStateToProps = ({  system: { wording, events }, projects: { projects } }) => {
    return { wording, events, projects };
};

export default connect(mapStateToProps)(Events);
