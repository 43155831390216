import React, {useState} from 'react';
import styles from './causes.module.scss';
import {ContentInfo} from "../../components/content-info/content-info";
import {CauseListCard} from "../../components/cause-list-card/cause-list-card";
import {ActionButton} from "../../components/action-button/action-button";
//import {UrgentDonate} from "../../components/urgent-donate/urgent-donate";
import {Link, useParams} from "react-router-dom";
import {connect} from "react-redux";

const Causes = ({causes, categories}) => {

    const [showedItem, setShowedItem] = useState(20);

    const filteredCauses = causes.filter(cause => cause.status === 'approved');

    const params = useParams();
    const getCurrentCategory = () => {
        const category = categories.find((category) => category.id === params.id);
        return category ? category.name : '';
    }

    const getCategoriesMenu = () => {
        const allProjectCategories = Array.from(new Set(causes.map(project => project.categories).flat()))
        const filteredCategories = categories.filter(category => allProjectCategories.includes(category.id));
        return <div style={{marginBottom: 20}}>
            {filteredCategories.map((category, index) => {
                return <Link key={index} to={`/categories/${category.id}`}><ActionButton icon={false} mini={true} key={index}>{category.name}</ActionButton></Link>
            })}
        </div>
    }

    const getCausesListCards = () => {
        return filteredCauses.map((cause, index) => {

            if (params.id && !cause.categories.includes(params.id)) return null;

            if (index >= showedItem) {
                return null;
            }
            return <CauseListCard cause={cause} key={cause.id} />;
        })
    }

    const loadMore = () => {
        setShowedItem(causes.length);
    }

    const nearestProjects = filteredCauses.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

    return (
        <div className={`${styles.causes}`}>
            <ContentInfo asideProjects={nearestProjects}>
                {getCategoriesMenu()}
                {params.id && <div style={{display: 'flex', gap: 10}}>
                    <Link to={`/causes`}><ActionButton icon={true} mini={true}>{'усе'}</ActionButton></Link>
                    <strong style={{marginBottom: 20, fontSize: 14, marginTop: 4, marginLeft: -15, textTransform: 'uppercase'}}>{getCurrentCategory()}</strong>
                </div>}
                {getCausesListCards()}
                {showedItem < causes.length &&
                    <div className={styles.actionButtonContainer}>
                        <ActionButton icon={false} onClick={loadMore}>Глядзець далей</ActionButton>
                    </div>
                }
            </ContentInfo>
            {/*<UrgentDonate causes={causes} />*/}
        </div>
    )
}

const mapStateToProps = ({  system: { categories } }) => {
    return { categories };
};

export default connect(mapStateToProps)(Causes);
