import React from 'react';
import styles from './cause-list-card.module.scss';
import './cause-list-card.scss';
import {Link} from "react-router-dom";
import {ProgressBar} from "react-bootstrap";
import {ActionButton} from "../action-button/action-button";

export const CauseListCard = ({cause}) => {
    const {id, image, title, raised, goal, percentage, text} = cause;

    return (
        <div className={`${styles.causeListCard} cause-list-card`}>
            <div className={styles.causesListBox}>
                {image
                    ?
                    <Link to={`/causes/${id}`}>
                        <img src={image} alt={'cause'} className={styles.image} style={{objectFit: 'cover'}}/>
                    </Link>
                    :
                    <Link to={`/causes/${id}`}>
                        <div className={styles.image}></div>
                    </Link>
                }
                <div className={styles.details}>
                    <h3>
                        <Link to={`/causes/${id}`}>{title}</Link>
                    </h3>
                    <ProgressBar now={percentage} />
                    <h4>Сабрана: <span className={styles.raised}>€{raised}</span> / <span>€{goal}</span></h4>
                    <p>{text}</p>
                </div>
            </div>
            <Link to={`/causes/${id}`}><ActionButton icon={false} className={styles.actionButton}>
                Дапамагчы
            </ActionButton></Link>
        </div>
    )
}
