import React from 'react';
import styles from './event-tab.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faClock} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import {getTextDate} from "../../helper/dates";

export const EventTab = ({ id, heading, image, date, time }) => {

    const dateObj = new Date(date);

    const year = dateObj.getFullYear()

    return (
        <div className={`${styles.eventTab}`}>
            <div className={styles.date}>
                <div className={styles.calendarIcon}>
                    <FontAwesomeIcon icon={faCalendarDays}/>
                </div>
                <h5>
                    {getTextDate(date, true)} <span className={styles.year}>{year}</span>
                </h5>
            </div>
            <div className={styles.eventDetails}>
                <h3>
                    <Link to={`/event/${id}`}>
                        {heading}
                    </Link>
                </h3>
                {time && <ul className={styles.info}>
                    <li>
                        <FontAwesomeIcon icon={faClock}/>
                        {time}
                    </li>

                </ul>}
            </div>
            <div className={styles.eventPic}>
                <Link to={`/event/${id}`}>
                    <img src={image} alt={'event-info'} style={{objectFit: 'cover'}}/>
                </Link>
            </div>
        </div>
    )
}
