import React, {useEffect} from 'react';
import styles from './recent-news-carousel.module.scss';
import './recent-news-carousel.scss';
import OwlCarousel from "react-owl-carousel";
import {RecentNewsItem} from "../recent-news-item/recent-news-item";
import {owlCarouselNavReset} from "../../helper/owlCarouselNavReset";

export const RecentNewsCarousel = ({recentNews = []}, wording) => {
    useEffect(() => {
        owlCarouselNavReset();
    }, []);

    const getNews = () => (
        recentNews.map((news, index) => <RecentNewsItem wording={wording} news={news} key={index} />)
    )

    const responsive = {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 2
        }
    }

    return (
        <div className={`${styles.recentNews} recent-news-carousel`}>
            <OwlCarousel className='owl-theme' loop margin={16} nav responsive={responsive}>
                {recentNews.length ? getNews() : null}
            </OwlCarousel>
        </div>
    )
}
