import React from "react";

const HBox = ({onClick, gap=20, width='fit-content', align='center', children, sb, style, wrap='wrap', className }) => {

    return (
        <div className={className} onClick={() => onClick && onClick()} style={{
            display: 'flex',
            gap: gap,
            width: width,
            alignItems: align,
            justifyContent: sb ? 'space-between' : 'flex-start',
            cursor: onClick ? 'pointer' : 'auto',
            flexWrap: wrap,
            ...style}}>
            { children }
        </div>
    )
}

export default HBox;
