import React, { useState } from "react";
import styles from './login.module.scss';
import {isEmail, isEmpty} from "../../helper/validators";
import VBox from "../ui/containers/layouts/vbox";
import HBox from "../ui/containers/layouts/hbox";
import { validatePin, logoutUser } from "../../redux/actions/actions-user";
import {connect} from "react-redux";
import {ActionButton} from "../action-button/action-button";
import APIService from "../../services/api-service/api-service";

const apiService = new APIService()

const LoginScreenEmail = props =>  {

    const { validatePin, token, wording } = props;

    const [page, setPage] = useState(0)
    const [email, setEmail] = useState('')
    const [pin, setPin] = useState('')

    const loginHandler = async () => {
        if (email && isEmail(email.trim())) {
            try {
                const result = await apiService.loginUserByEmail(email.trim())
                if (result) {
                    setPage(1)
                } else {
                    // error
                }
            } catch (e) {
                // error
            }
        } else {
            // error
        }
    }

    /*
    const codeBackupHandler = async () => {
        if (email && isEmail(email.trim())) {
            try {
                const result = await apiService.loginUserByEmail(email.trim(), true)
                if (result) {
                    setPage(1)
                    window.alert('email sent from gmail box')
                    // success
                } else {
                    // error
                }
            } catch (e) {
                // error
            }
        } else {
            // error
        }
    }

     */

    const validateHandler = () => {
        if (email && isEmail(email.trim()) && pin && !isEmpty(pin) && pin.trim().length <= 10) {

            validatePin(email.trim(), pin.trim())

        } else {
            // error
        }
    }

    const onKeyDownCode = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            await loginHandler()
        }
    }

    const onKeyDownValidate = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            validateHandler()
        }
    }

    //if (token) return null

    return (
        <div className={styles.login}>
            {!token && <div className={styles.panel}>
                {page === 0 && <VBox gap={10}>
                    <div>{`${wording['login-step1-email']}`}</div>
                    <input
                        onKeyDown={onKeyDownCode}
                        placeholder={'user@gmail.com'}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <div>
                        {/*<button disabled={!isEmail(email.trim())} className={styles.button} onClick={() => loginHandler()}>{'send code'}</button>*/}
                        <ActionButton
                            disabled={!isEmail(email.trim())}
                            icon={false}
                            onClick={loginHandler}
                        >
                            {wording['login-step1-button']}
                        </ActionButton>
                    </div>
                </VBox>}
                {page === 1 && <VBox gap={10}>
                    <div>{`${wording['login-step2-check']} ${email}. ${wording['login-step2-pin']}`}</div>
                    <input
                        type='password'
                        placeholder={'XXXXXX'}
                        value={pin}
                        onKeyDown={onKeyDownValidate}
                        onChange={(e) => setPin(e.target.value)}
                    />

                    <HBox>
                        {/*<button disabled={isEmpty(pin.trim())} className={styles.button} onClick={() => validateHandler()}>{'confirm code'}</button>*/}
                        <ActionButton
                            disabled={isEmpty(pin.trim())}
                            icon={false}
                            onClick={validateHandler}
                        >
                            {wording['login-step2-confirm']}
                        </ActionButton>
                        <ActionButton
                            mini
                            onClick={() => setPage(0)}
                        >
                            {wording['login-step2-retry']}
                        </ActionButton>
                    </HBox>
                </VBox>}
            </div>}
        </div>
    );
}

const mapStateToProps = ({  user: { token, userEmail }, system: { wording } }) => {
    return { token, userEmail, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validatePin: validatePin(dispatch),
        logoutUser: logoutUser(dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreenEmail);
