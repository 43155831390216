import React, {useEffect, useState} from 'react';
import styles from './profile-home.module.scss';
import './profile-home.scss';
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown, faAt, faChevronRight, faCreditCard, faMinus, faPlus, faUser, faWallet,
} from "@fortawesome/free-solid-svg-icons";
import {AboutInfo} from "../about-info/about-info";
import VBox from "../ui/containers/layouts/vbox";
import {connect} from "react-redux";
import {logoutUser} from "../../redux/actions/actions-user";
import APIService from "../../services/api-service/api-service";
import LoginScreenEmail from "../login-screen-email";
import HBox from "../ui/containers/layouts/hbox";
import {ActionButton} from "../action-button/action-button";
import {loadProjects} from "../../redux/actions/actions-projects";
import {faBitcoin} from "@fortawesome/free-brands-svg-icons";
import {dateToStringDateTime} from "../../helper/dates";
import ReactMarkdown from "react-markdown";

const apiService = new APIService()

const ProfileInfo = ({ wording, token, profile, logoutUser, userEmail }) => {

    const [profileData, setProfileData] = useState(profile || {})

    const getProfileData = async () => {
        try {
            const result = await apiService.getCrowdfundingProfile()
            if (result) {
                setProfileData(result)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (token) getProfileData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const getProjectStatus = (status) => {
        if (status === 'approved') return <mark style={{fontSize: 12, borderRadius: 4, padding: '0px 5px'}}>{wording['profile-label-process']}</mark>
        if (status === 'completed' || status === 'failed') return <mark style={{fontSize: 12, borderRadius: 4, padding: '0px 5px', background: '#23A884', color: '#FFF'}}>{wording['profile-label-completed']}</mark>
        //if (status === 'failed') return <mark style={{fontSize: 12, borderRadius: 4, padding: '0px 5px', background: '#a82323', color: '#FFF'}}>{`няўдалы`}</mark>
    }

    //if (!profileData || !profileData.totalSum) return null

    const {
        userProjects,
        userProjectsWithDonations,
        walletSum,
        walletData,
        totalSum,
    } = profileData

    const getPaymentIcon = (transport) => {
        if (transport === 'crypto') return faBitcoin
        if (transport === 'kuna') return faBitcoin
        if (transport === 'wallet') return faWallet
        return faCreditCard
    }


    return (
        <Container className={`${styles.about} about`}>
            <Row className={styles.aboutContainer}>
                <Col className={styles.aboutCol}>
                    <AboutInfo
                        subTitle={''}
                        title={wording['top-menu-profile']}
                        text={''}
                    />
                    {!token && <VBox gap={10}>
                        <LoginScreenEmail />
                    </VBox>}
                    {token && <VBox>
                        <HBox gap={10}>
                            {userEmail}
                            <ActionButton mini onClick={logoutUser}>Змяніць</ActionButton>
                        </HBox>
                    </VBox>}
                    {token && <>
                        <h1 style={{marginTop: 20}}>{`${wording['profile-total-sum']} `}<mark style={{ padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF' }}>{`€${totalSum}`}</mark></h1>
                        <h3 style={{marginBottom: 30}}>{`${wording['profile-wallet-sum']} `}
                            <mark style={{padding: '1px 10px', borderRadius: 10, background: '#23A884', color: '#FFF'}}>{`€${walletSum}`}</mark>
                        </h3>
                    </>}
                    {token && <Accordion>
                        {userProjects?.length > 0 && <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                {wording['profile-section-1']}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line', width: '100%'}}>
                                    <VBox width={'100%'}>
                                        {userProjects && userProjects.map((project, index) =>
                                            <VBox gap={10} key={index} width={'100%'}>
                                                <HBox gap={10} align={'flex-start'} width={'100%'} wrap={'nowrap'}>
                                                    <img src={project.image} style={{
                                                        width: 50,
                                                        height: 50,
                                                        borderRadius: 6,
                                                        objectFit: 'cover'
                                                    }} alt={'cause'}/>
                                                    <VBox gap={0} width={'100%'}>
                                                        <HBox gap={10}><strong>{project.title}</strong></HBox>
                                                        <HBox gap={8}>
                                                            <span>{`€${project.raised} / €${project.goal}`}</span>
                                                            <FontAwesomeIcon style={{height: 10}}
                                                                             icon={faChevronRight}/>
                                                            <span
                                                                style={{marginTop: -3}}>{getProjectStatus(project.status)}</span>
                                                        </HBox>
                                                        <VBox gap={10} style={{marginTop: 10}} width={'100%'}>
                                                            {project.payments.map((payment, paymentIndex) => <VBox key={paymentIndex} width={'100%'} gap={5}>
                                                                {<div style={{
                                                                    borderBottom: '1px #DDD solid',
                                                                    width: '100%',
                                                                    marginTop: 10,
                                                                    marginBottom: 10
                                                                }}/>}<HBox
                                                                    gap={8}
                                                                    key={paymentIndex}>
                                                                    <FontAwesomeIcon color={'#23A884'}
                                                                                     icon={getPaymentIcon(payment.transport)}/>
                                                                    <strong style={{fontSize: 16, width: 60}}>{`€${payment.amount / 100}`}</strong>
                                                                    <span style={{fontSize: 14, width: 250}}><FontAwesomeIcon color={'#666666'} style={{fontSize: 12}} icon={payment.userEmail ? faAt : faUser}/>&nbsp;{` ${payment.userEmail || 'Anonymous'}`}</span>
                                                                    <span style={{
                                                                        fontSize: 14,
                                                                        color: '#888888'
                                                                    }}>{`${dateToStringDateTime(payment.date, true, false, 'by')}. ${payment.skipReward ? wording['reward-skipped'] : ''}`}</span>
                                                                </HBox>
                                                            </VBox>)}

                                                        </VBox>
                                                    </VBox>
                                                </HBox>
                                                {index < userProjectsWithDonations?.length - 1 && <div style={{
                                                    borderBottom: '1px #DDD solid',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginBottom: 10
                                                }}/>}
                                            </VBox>)}
                                    </VBox>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>}
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                {wording['profile-section-2']}
                                <FontAwesomeIcon icon={faAngleDown}/>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line', width: '100%'}}>
                                    <VBox width={'100%'}>
                                        {userProjectsWithDonations && userProjectsWithDonations.map((project, index) =>
                                            <VBox gap={10} key={index} width={'100%'}>
                                                <HBox gap={10} align={'flex-start'} wrap={'nowrap'}>
                                                    <img src={project.image} style={{
                                                        width: 50,
                                                        height: 50,
                                                        borderRadius: 6,
                                                        objectFit: 'cover'
                                                    }} alt={'cause'}/>
                                                    <VBox gap={0}>
                                                        <HBox gap={10}><strong>{project.title}</strong></HBox>
                                                        <HBox gap={8}>
                                                            <span>{`€${project.raised} / €${project.goal}`}</span>
                                                            <FontAwesomeIcon style={{height: 10}}
                                                                             icon={faChevronRight}/>
                                                            <span
                                                                style={{marginTop: -3}}>{getProjectStatus(project.status)}</span>
                                                        </HBox>
                                                        <VBox gap={10} style={{marginTop: 10}}>
                                                            {project.payments.map((payment, paymentIndex) => <HBox
                                                                gap={8}
                                                                key={paymentIndex}>
                                                                <FontAwesomeIcon color={'#23A884'}
                                                                                 icon={getPaymentIcon(payment.transport)}/>
                                                                <strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                        width: 40
                                                                    }}>{`€${payment.amount / 100}`}</strong>
                                                                <span style={{
                                                                    fontSize: 14,
                                                                    color: '#888888', width: 100
                                                                }}>{`${dateToStringDateTime(payment.date, true, true, 'by')}`}</span>
                                                                {payment.skipReward && <span style={{
                                                                    fontSize: 14,
                                                                    color: '#888888', width: 150
                                                                }}>{wording['reward-skipped']}</span>}
                                                                {payment.rewardsArray?.length > 0 && <span style={{
                                                                    fontSize: 10,
                                                                    color: '#888888'
                                                                }}><ReactMarkdown>{payment.rewardsArray}</ReactMarkdown></span>}
                                                            </HBox>)}
                                                        </VBox>
                                                    </VBox>
                                                </HBox>
                                                {index < userProjectsWithDonations?.length - 1 && <div style={{
                                                    borderBottom: '1px #DDD solid',
                                                    width: '100%',
                                                    marginTop: 10,
                                                    marginBottom: 10
                                                }}/>}
                                            </VBox>)}
                                    </VBox>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {walletData?.length > 0 && <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                {`${wording['profile-section-3']} €${walletSum}`}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body style={{width: '100%'}}>
                                <div style={{width: '100%'}}>
                                    <VBox gap={10} style={{marginTop: 10}} width={'100%'}>
                                        {walletData.map((payment, paymentIndex) => <HBox
                                            width={'100%'}
                                            gap={10}
                                            key={paymentIndex}>
                                            <FontAwesomeIcon color={'#888888'}
                                                             icon={payment.transport === 'wallet' ? faMinus : faPlus}/>
                                            <FontAwesomeIcon color={'#23A884'}
                                                             icon={getPaymentIcon(payment.transport)}/>
                                            <strong
                                                style={{fontSize: 16}}>{`€${payment.amount / 100}`}</strong>
                                            <span style={{
                                                fontSize: 14,
                                                color: '#888888'
                                            }}>{`${dateToStringDateTime(payment.date, true, true, 'by')}.`}</span>
                                            {payment.projectHeading && <div style={{
                                                fontSize: 14,
                                                color: '#888888',
                                                flex: 1
                                            }}>{`${wording[payment.transport === 'wallet' ? 'reward-payment' : 'reward-return']} «${payment.projectHeading}»`}</div>}
                                        </HBox>)}
                                        <div style={{marginTop: 20, fontSize: 12}}>{wording['reward-request']} <a
                                            href={'mailto:gronkapost@gmail.com'} target={'_blank'}
                                            rel={'noreferrer noopener'}>gronkapost@gmail.com</a></div>
                                    </VBox>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>}
                    </Accordion>}
                    <div style={{height: 100}}/>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = ({  projects: { projects }, system: { wording }, user: { token, profile, userEmail } }) => {
    return { projects, wording, token, profile, userEmail };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadProjects: loadProjects(dispatch),
        logoutUser: logoutUser(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
