import React from 'react';
import styles from './aside.module.scss';
import {Article} from "../article/article";
import {connect} from "react-redux";


const Aside = ({ className, projects, wording, events }) => {

    return (
        <div className={`${styles.aside} ${className}`}>
            {/*<AsideSearch />*/}
            {/*<Article heading={'Апошнія навіны'} basePath={'post'} articles={recentPost.result} />*/}
            <Article wording={wording} mini heading={wording['ending-soon-heading']} basePath={'causes'} articles={projects?.filter(el => el.status === 'approved')?.slice(0, 3)} />
            <Article wording={wording} heading={wording['top-menu-events']} basePath={'event'} articles={events?.slice(0, 2)} />
        </div>
    )
}

const mapStateToProps = ({  system: { wording, events } }) => {
    return { events, wording };
};

export default connect(mapStateToProps)(Aside);
