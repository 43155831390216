import React, { useEffect, useState } from 'react';
import APIService from "../../services/api-service/api-service";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePaymentElement from "./stripe-payment-element";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const apiService = new APIService()

const StripeWidget = ({ itemId=1, amount=500, title, clientUrlSecret, skipReward, rewardsArray }) => {

    const [clientSecret, setClientSecret] = useState("");

    const getPaymentClientSecret = async () => {
        try {
            const result = await apiService.getPaymentIntent(itemId, amount, skipReward, rewardsArray);
            if (result) {
                setClientSecret(result)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        /*
        fetch("/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ amount }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));

         */
        getPaymentClientSecret()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        }
    };


    return (
        <div >
            <h4>{title}</h4>
            <h4>{'EUR ' + Math.floor(amount / 100)}</h4>

            {clientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <StripePaymentElement itemId={itemId} clientUrlSecret={clientUrlSecret}/>
                </Elements>
            )}

        </div>
    );

}

export default StripeWidget;
