import React from 'react';
import styles from './content-page.module.scss';
import {AboutInfo} from "../../components/about-info/about-info";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";

const ContentPage = ({ heading, content}) => {

    return (
        <div className={`${styles.about}`}>
            <Container className={styles.container}>
                <Row>
                    <Col md={0} lg={7} className={styles.info}>
                        <AboutInfo
                            subTitle={''}
                            title={heading}
                            text={content}
                        />
                    </Col>

                </Row>
            </Container>

        </div>
    )
}
const mapStateToProps = ({  system: { wording },  }) => {
    return { wording };
};
export default connect(mapStateToProps)(ContentPage);
