import React from 'react';
import styles from './about-info.module.scss';
import ReactMarkdown from 'react-markdown'
import './about-info.scss';

export const AboutInfo = ({
    subTitle,
    title,
    text
}) => {
    return (
        <div className={styles.aboutCompany}>
            {subTitle && <span>{subTitle}</span>}
            {title && <h2>{title}</h2>}
            {text && <ReactMarkdown className={styles.preLine}>{text}</ReactMarkdown>}
        </div>
    )
}
