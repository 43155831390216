
export const PROJECTS_LOADED = 'PROJECTS_LOADED';
export const EVENTS_LOADED = 'EVENTS_LOADED';
export const NEWS_LOADED = 'NEWS_LOADED';

export const PROFILE_LOADED = 'PROFILE_LOADED'
export const USER_LOADED = 'USER_LOADED'
export const USER_ERROR = 'USER_ERROR'

export const SYSTEM_DATA_LOADED = 'SYSTEM_DATA_LOADED'
export const SYSTEM_LANGUAGE_UPDATED = 'SYSTEM_LANGUAGE_UPDATED'

export const createAction = (type, payload) => {
    return { type, payload }
}
