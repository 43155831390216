import APIService from "../../services/api-service/api-service";
import Cookies from 'js-cookie'

import {
    createAction,
    USER_ERROR,
    USER_LOADED,
    SYSTEM_DATA_LOADED, SYSTEM_LANGUAGE_UPDATED, PROFILE_LOADED,
} from "../actions-factory";
import {loadProjects} from "./actions-projects";


const apiService = new APIService();

export const logoutUser = (dispatch) => async () => {

    dispatch(createAction(USER_ERROR, {userId: "", token: "", email: "", communitiesAttendance: []}))
    Cookies.remove('crw-name')
    Cookies.remove('crw-userId')
    Cookies.remove('crw-email')

}

export const updateUserProfile = (dispatch) => () => {
    apiService.getCrowdfundingProfile()
        .then(async (data) => {
            dispatch(createAction(PROFILE_LOADED, data))
        })
        .catch(async (err) => {
            console.log(err)
        });
}

export const validatePin = (dispatch) => async (email, pin) => {

    apiService.validateUserPin(email, pin)
        .then(async (data) => {
            dispatch(createAction(USER_LOADED, data))
            if (data.token) {
                try {
                    Cookies.set('crw-name', data.token, { expires: 30 }) //expires in 30d
                    Cookies.set('crw-userId', data.userId, { expires: 30 }) //expires in 30d
                    Cookies.set('crw-email', data.email, { expires: 30 }) //expires in 30d
                } catch (e) {
                    console.log(e)
                }

                updateUserProfile(dispatch)()

            }
            //loadLatestUserSettings(dispatch)()
        })
        .catch(async (err) => {

            dispatch(createAction(USER_ERROR, err));
        });
}


export const getSystemData = (dispatch) => async (language='by') => {

    try {
        const cookieToken = Cookies.get('crw-name')
        const cookieUserId = Cookies.get('crw-userId')
        const cookieUserEmail = Cookies.get('crw-email')
        const cookieLanguage = Cookies.get('crw-language')

        if (cookieToken) {
            dispatch(createAction(USER_LOADED, { userId: cookieUserId, token: cookieToken, email: cookieUserEmail }))

            Cookies.set('crw-name', cookieToken, { expires: 30 }) //expires in 30d
            Cookies.set('crw-userId', cookieUserId, { expires: 30 }) //expires in 30d
            Cookies.set('crw-email', cookieUserEmail, { expires: 30 }) //expires in 30d

            updateUserProfile(dispatch)()

        }

        if (cookieLanguage) {
            dispatch(createAction(SYSTEM_LANGUAGE_UPDATED, cookieLanguage))
        }

        apiService.loadSystemData(cookieLanguage || language)
            .then(async (data) => {
                dispatch(createAction(SYSTEM_DATA_LOADED, data))

                loadProjects(dispatch)(cookieLanguage || language)

            })
            .catch(async (err) => {
                console.log(err)
            });

    } catch (e) {
        console.log(e)
    }

}

export const updateLanguage = (dispatch) => async (language) => {
    Cookies.set('crw-language', language, { expires: 30 })
    dispatch(createAction(SYSTEM_LANGUAGE_UPDATED, language))
    getSystemData(dispatch)(language)
    //loadProjects(dispatch)(language)
}
