import React from 'react';
import styles from './cause-card.module.scss';
import {Card} from "react-bootstrap";
import defaultCauseImage from "../../assets/images/cause.jpg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {CircleProgress} from "../circle-progress/circle-progress";
import {Link} from "react-router-dom";
//import ReactMarkdown from "react-markdown";

export const CauseCard = ({cause, mini, wording}) => {
    const {id, image, title, remaining, raised, goal, percentage, status} = cause;

    return (
        <div className={`${styles.causeCardContainer} item causeCardContainer`}>
            <Card className={styles.causeCard}>
                <Link to={`/causes/${id}`}>
                    <Card.Img variant="top" src={image ? image : defaultCauseImage} className={styles.causeImg}/>
                </Link>
                <Card.Body>
                    <Card.Title className={styles.causesTitle}>
                        <Link to={`/causes/${id}`}>{title}</Link>
                    </Card.Title>
                    {status === 'approved' && <div className={styles.remainingDays}>
                        <FontAwesomeIcon icon={faCalendarDays}/>
                        {remaining} {wording['days-left']} {wording['left-heading']}
                    </div>}
                    {!mini && <div className={styles.circleProgressContainer}>
                        <CircleProgress percentage={percentage} duration={3000} />
                    </div>}
                    <div className={styles.numbersContainer}>
                        <div>
                            <div className={styles.raisedTitle}>{wording['collected-heading']?.toLowerCase()}</div>
                            <div className={styles.raisedNumb}>€{raised}</div>
                        </div>
                        <div>
                            <div className={styles.goalTitle}>{wording['goal-heading']?.toLowerCase()}</div>
                            <div className={styles.goalNumb}>€{goal}</div>
                        </div>
                    </div>
                    {/*<div><ReactMarkdown className={styles.causesPreline}>{reward}</ReactMarkdown></div>*/}
                </Card.Body>
            </Card>
        </div>
    )
}
