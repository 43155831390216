import React from 'react';
import styles from './recent-news-item.module.scss';
import {Card} from "react-bootstrap";
import defaultCauseImage from "../../assets/images/cause.jpg";
import {getTextDate} from "../../helper/dates";
import {Link} from "react-router-dom";

export const RecentNewsItem = ({news, wording}) => {
    const {image, heading, organization, date, id} = news;

    return (
        <>
            <Card className={styles.newsCard}>
                <div className={styles.imageContainer}>
                    <Link to={`/news/${id}`}><Card.Img variant="top" src={image ? image : defaultCauseImage} /></Link>
                    {organization && <div className={styles.organization}>
                        <span>{organization}</span>
                    </div>}
                </div>
                <Card.Body>
                    <Card.Title>
                        <Link to={`/news/${id}`}>{heading}</Link>
                    </Card.Title>
                    {date && <div className={styles.info}>
                        {getTextDate(date, true)}, {new Date(date).getFullYear()}
                    </div>}
                    <div className={styles.link}>
                        <Link to={`/news/${id}`}>{wording['see-more-heading']}</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
