// configuration constants

export const serverUrl = process.env.REACT_APP_API_URL;
export const stageUrl = process.env.REACT_APP_API_STAGE_URL;
export const supportUrl = process.env.REACT_APP_SUPPORT_URL;
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

export const socialInstagramUrl = process.env.REACT_APP_SOCIAL_INSTAGRAM;
export const socialFacebookUrl = process.env.REACT_APP_SOCIAL_FACEBOOK;
export const socialTelegramUrl = process.env.REACT_APP_SOCIAL_TELEGRAM;

export const languages = process.env.REACT_APP_LANGUAGES?.split(',');
export const defaultLanguage = languages[0];
