import {
    EVENTS_LOADED,
    NEWS_LOADED,
    PROJECTS_LOADED,
} from "../actions-factory";

function countDaysBetween(date1, date2) {

    let date1_ms = date1.getTime();
    let date2_ms = date2.getTime();
    let difference_ms = Math.max(date1_ms - date2_ms, 0);

    // Convert back to days and return
    return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

const updateProjects = (state, action) => {

    if (state === undefined) {
        return {
            projects: [],
            news: [],
            events: [],
        };
    }

    switch (action.type) {

        case PROJECTS_LOADED: {

            const projects = action.payload.map((project) => {
                return {
                    ...project,
                    percentage: project.goal ? Math.floor((project.raised / project.goal) * 100) : 0,
                    remaining: countDaysBetween(new Date(project.deadline), new Date()),
                }
            }).sort( () => .5 - Math.random())

            return {
                ...state.projects,
                projects: projects,
            };
        }

        case NEWS_LOADED: {
            return {
                ...state.projects,
                news: action.payload
            };
        }

        case EVENTS_LOADED: {
            return {
                ...state.projects,
                events: action.payload
            };
        }

        default:
            return state.projects;
    }
};

export default updateProjects;
