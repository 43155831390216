import React from 'react';
import styles from './main-carousel.module.scss';
import './main-carousel.scss';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from "../../assets/images/slider/slide-1.jpg";
import {ActionButton} from "../action-button/action-button";
import {Link} from "react-router-dom";

export const CHMainCarousel = ({ carouselData, wording }) => {

    const getCarouselItem = () => (
        carouselData.map((data, index) => {
            const {image = '', title = '', subheading='', id } = data;
                return (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100 main-carousel-image"
                            src={image ? image : slide1}
                            alt="slide"
                        />
                        <Carousel.Caption className={styles.carouselCaption}>
                            <h3>{title}</h3>
                            <p>{subheading}</p>
                            <Link to={`/causes/${id}`}>
                                <ActionButton className={styles.mainCarouselAction}>
                                    {wording['donation-button']}
                                </ActionButton>
                            </Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            }
        )
    );

    return (
        <div className={`${styles.mainCarousel} main-carousel`}>
            <Carousel interval={null}>
                {getCarouselItem()}
            </Carousel>
        </div>
    )
}
