import React from 'react';
import styles from './info-container.module.scss';

export const InfoContainer = ({title = '', text = '', children, white, className}) => {
    return (
        <div className={`${styles.categoriesWrapper} ${white && styles.white} ${className}`}>
            <div className={styles.categories}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.text}>{text}</p>
                {children}
            </div>
        </div>
    )
}