import updateUser from "./updateUser";
import updateProjects from "./updateProjects";
import updateSystem from "./updateSystem";

const reducer = (state, action) => {
    return {
        user: updateUser(state, action),
        projects: updateProjects(state, action),
        system: updateSystem(state, action)
    };
};

export default reducer;
