import {
    SYSTEM_DATA_LOADED, SYSTEM_LANGUAGE_UPDATED
} from "../actions-factory";

const updateSystem = (state, action) => {

    if (state === undefined) {
        return {
            wording: {},
            categories: [],
            tags: [],
            creators: [],
            news: [],
            events: [],
            totalCollected: 0,
            language: 'by'
        };
    }

    switch (action.type) {

        case SYSTEM_DATA_LOADED: {
            return {
                ...state.system,
                wording: action.payload?.wording || {},
                categories: action.payload?.categories || [],
                tags: action.payload?.tags || [],
                creators: action.payload?.creators || [],
                news: action.payload?.news || [],
                events: action.payload?.events || [],
                totalCollected: action.payload?.totalCollected || 0,

            };
        }

        case SYSTEM_LANGUAGE_UPDATED: {
            return {
                ...state.system,
                language: action.payload
            };
        }

        default:
            return state.system;
    }
};

export default updateSystem;
