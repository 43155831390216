import React from 'react';
import styles from './facts.module.scss';
import {Col, Row} from "react-bootstrap";

import {Fact} from "../fact/fact";
import {connect} from "react-redux";

const Facts = ({ bgImage = '', className, projects=[], wording, totalCollected }) => {

    console.log(totalCollected)

    const labeledFacts = [
        {
            "title": wording['fact1'],
            "percentage": projects?.filter(project => project.status === 'completed').length
        },
        {
            "title": `${wording['fact2']}, €`,
            "percentage": totalCollected
        },
        {
            "title":  wording['fact3'],
            "percentage": projects?.filter(project => project.status === 'approved').length
        },
        {
            "title": wording['fact4'],
            "percentage": projects?.filter(project => project.status === 'failed').length
        }
    ]

    const getFacts = () => {
        return labeledFacts.map((fact, index) => (
            <Col md={3} sm={6} xs={12} key={index + fact.percentage}>
                <Fact title={fact.title} percentage={fact.percentage} className={className}></Fact>
            </Col>
        ))
    }

    return (
        <div className={`${styles.facts}`} style={{backgroundImage: bgImage ? `url(${bgImage})` : ''}}>
            <Row className={styles.content}>
                {getFacts()}
            </Row>
        </div>
    )
}

const mapStateToProps = ({  projects: { projects }, system: { wording, totalCollected } }) => {
    return { projects, wording, totalCollected };
};

export default connect(mapStateToProps)(Facts);
