import React, { useState, useEffect } from "react";
import {isUrl} from "../../helper/validators";
import VBox from "../ui/containers/layouts/vbox";
import HBox from "../ui/containers/layouts/hbox";
import { validatePin, logoutUser } from "../../redux/actions/actions-user";
import {connect} from "react-redux";
import {ActionButton} from "../action-button/action-button";
import APIService from "../../services/api-service/api-service";
import LoginScreenEmail from "../login-screen-email";

const apiService = new APIService()

const getFormUrl = (email, token) => {
    return `https://airtable.com/app4TCVg0SylAAVYr/shrdLfl5ioZmFzdH7?prefill_authorEmail=${email}&hide_authorEmail=true&prefill_token=${token}&hide_token=true`
}

const CreateProjectBlock = props =>  {

    const { token, wording, userEmail, logoutUser } = props;

    const [verificationToken, setVerificationToken] = useState(null)
    const [verificationEmail, setVerificationEmail] = useState(null)

    const validateVerification = async () => {
        if (!token) return
        try {
            const result = await apiService.validateUserVerification()
            if (result?.token && result?.email) {
                setVerificationToken(result.token)
                setVerificationEmail(result.email)
            } else {
                setVerificationToken(false)
            }
        } catch (e) {
            console.log(e)
            setVerificationToken(false)
        }
    }

    useEffect(() => {
        validateVerification();
        let interval = null;
        interval = setInterval(() => {
            if (token && !verificationToken) validateVerification();
        }, 5000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, verificationToken]);

    const startValidation = async () => {

        try {
            const result = await apiService.registerUserVerification()
            if (result) {

                if (isUrl(result)) {
                    window.open(result, '_blank')
                }
                // success
            } else {
                // error
            }
        } catch (e) {
            // error
        }
    }

    //if (token) return null

    return (
        <VBox style={{marginTop: 20}} gap={30}>
            <VBox gap={10}>
                <strong>{wording['new-verification-step1']}</strong>
                {!token && <VBox>
                    <LoginScreenEmail />
                </VBox>}
                {token && <VBox>
                    <HBox gap={10}>
                        {userEmail}
                        <ActionButton mini onClick={logoutUser}>Змяніць</ActionButton>
                    </HBox>
                </VBox>}
            </VBox>
            <VBox gap={10}>
                <strong>{wording['new-verification-step2']}</strong>
                {token && verificationToken === false && <VBox>
                    <ActionButton
                        icon={false}
                        onClick={startValidation}
                    >
                        {wording['new-verification-start']}
                    </ActionButton>
                </VBox>}
                {verificationToken && <VBox>
                    <p>✅&nbsp;&nbsp;{wording['new-verification-done']}</p>
                </VBox>}
            </VBox>
            <VBox gap={10}>
                <strong>{wording['new-verification-step3']}</strong>
                {verificationToken && verificationEmail && <VBox>
                    <ActionButton
                        icon={false}
                        link={getFormUrl(verificationEmail, verificationToken)}
                    >
                        Запоўніць форму
                    </ActionButton>
                </VBox>}
            </VBox>
        </VBox>
    );
}

const mapStateToProps = ({  user: { token, userEmail }, system: { wording } }) => {
    return { token, userEmail, wording };
};

const mapDispatchToProps = (dispatch) => {
    return {
        validatePin: validatePin(dispatch),
        logoutUser: logoutUser(dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectBlock);
