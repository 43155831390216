import React, {useState} from 'react';
import styles from './navbar.module.scss';
import './navbar.scss';
import {Offcanvas, Container, Nav, Navbar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import HBox from "../ui/containers/layouts/hbox";

export const CHNavbar = ({ wording, walletAmount }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const expand = 'lg';

    return (
        <Navbar expand="lg" className={`${styles.CH_navbar} CH_navbar`}>
            <Container className={styles.navbarContainer} fluid="md">

                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow}>
                    <FontAwesomeIcon icon={faBars} />
                </Navbar.Toggle>

                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    show={show}
                    onHide={handleClose}
                >
                    <Offcanvas.Header>
                        <button onClick={handleClose} className={'CH_close-button'}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className={'CH_mobile-nav'}>
                            <Link to={'/'} className={'nav-link'}>{wording['top-menu-home']}</Link>
                            <Link to={'/causes'} className={'nav-link'}>{wording['top-menu-projects']}</Link>
                            <Link to={'/events'} className={'nav-link'}>{wording['top-menu-events']}</Link>
                            <Link to={'/add'} className={'nav-link'}>{wording['add-project-button']}</Link>
                            <Link to={'/creators'} className={'nav-link'}>{wording['top-menu-creators']}</Link>
                            <Link to={'/about'} className={'nav-link'}>{wording['top-menu-about']}</Link>
                            <Link to={'/profile'} className={'nav-link'} style={{ cursor: 'pointer' }}>
                                <HBox gap={8} align={'center'} style={{ cursor: 'pointer' }}>{wording['top-menu-profile']}
                                {!isNaN(walletAmount) && <div style={{marginTop: -5}}>
                                    <mark style={{ padding: '2px 6px', borderRadius: 10, background: '#23A884', color: '#FFF', fontSize:10 }}>€{walletAmount}</mark>
                                </div>}
                                </HBox>
                            </Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}
