import React from 'react';
import styles from './footer.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import Logo from '../../assets/logo/gronka_white.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {socialInstagramUrl, socialTelegramUrl, supportUrl} from "../../configs/keys";

const Footer = ({ wording }) => {
    return (
        <footer className={`${styles.footer} footer`}>
            <Container>
                <Row className={styles.footerRow}>
                    <Col>
                        <div className={`${styles.widget} ${styles.aboutWidget}`}>
                            <div className={styles.footerLogo}>
                                <img src={Logo} alt={'logo'} className={`${styles.logo}`}/>
                            </div>
                            <div className={styles.companyInfo}>
                                <div style={{display: 'flex', gap: 30, flexWrap: 'wrap'}}>
                                    <ul>
                                        <li><Link to={'/about'}>{wording['footer-heading-about']}</Link></li>
                                        <li><Link to={'/memo'}>{wording['footer-heading-memorandum']}</Link></li>
                                        <li><Link to={'/press'}>{wording['footer-heading-press']}</Link></li>

                                    </ul>
                                    <ul>

                                        <li><Link to={'/help-center'}>{wording['footer-heading-help-center']}</Link>
                                        </li>
                                        <li><Link to={'/how'}>{wording['footer-heading-howwe']}</Link></li>
                                        <li><Link to={'/rules'}>{wording['footer-heading-rules']}</Link></li>
                                        <li><Link to={'/creators'}>{wording['footer-heading-creators']}</Link></li>
                                    </ul>
                                    <ul>

                                        <li><Link to={'/faq'}>{wording['footer-heading-faq']}</Link></li>
                                        <li><Link to={'/usage'}>{wording['footer-heading-usage']}</Link></li>
                                        <li><Link to={'/policy'}>{wording['footer-heading-policy']}</Link></li>
                                        <li><Link to={'/stripe-policy'}>{wording['footer-heading-stripe-policy']}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <ul className={styles.socialLinks}>
                                <li>
                                    <a href={socialTelegramUrl} target={'_blank'} rel={'noreferrer noopener'}>
                                        <FontAwesomeIcon icon={faTelegram}/>
                                    </a>
                                </li>
                                <li>
                                    <a href={socialInstagramUrl} target={'_blank'} rel={'noreferrer noopener'}>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </li>
                                <li>
                                    <a href={`mailto:${supportUrl}`} target={'_blank'} rel={'noreferrer noopener'}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            Stichting (de) Lokale<br />
                            Fluwelen Burgwal 58, 2511CJ, Hague, Netherlands
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

const mapStateToProps = ({system: {wording}}) => {
    return {wording};
};

export default connect(mapStateToProps)(Footer);
