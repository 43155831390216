import React from 'react';
import styles from './event-date.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {getTextDate} from "../../helper/dates";

export const EventDate = ({eventDate, className}) => {

    const year = new Date(eventDate).getFullYear()

    return (
        <div className={`${styles.date} ${className}`}>
            <FontAwesomeIcon icon={faCalendarDays}/>
            {getTextDate(eventDate, true)}, {year}
        </div>
    )
}
