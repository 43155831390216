import React from 'react';
import styles from './content-info.module.scss';
import Aside from "../../components/aside/aside";
import {Col, Container, Row} from "react-bootstrap";

export const ContentInfo = ({children, asideProjects, customContent}) => {
    return (
        <div className={styles.contentInfo}>
            <Container>
                <Row>
                    <Col md={9} className={styles.content}>
                        {children}
                    </Col>
                    <Col md={3}>
                        {customContent ? customContent : <Aside projects={asideProjects || []} />}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
