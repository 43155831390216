import store from "../redux/store";

const getLanguage = () => {
    return store.getState().system.language.toLowerCase()
}

const months = {
    ru: ["ЯНВАРЯ", "ФЕВРАЛЯ", "МАРТА", "АПРЕЛЯ", "МАЯ", "ИЮНЯ", "ИЮЛЯ", "АВГУСТА", "СЕНТЯБРЯ", "ОКТЯБРЯ", "НОЯБРЯ", "ДЕКАБРЯ"],
    ua: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
    be: ["СТУДЗЕНЯ", "ЛЮТАГА", "САКАВIКА", "КРАСАВIКА", "МАЯ", "ЧЭРВЕНЯ", "ЛIПЕНЯ", "ЖНИЎНЯ", "ВЕРАСНЯ", "КАСТРЫЧНIКА", "ЛIСТАПАДА", "СЬНЕЖНЯ"],
    by: ["СТУДЗЕНЯ", "ЛЮТАГА", "САКАВIКА", "КРАСАВIКА", "МАЯ", "ЧЭРВЕНЯ", "ЛIПЕНЯ", "ЖНИЎНЯ", "ВЕРАСНЯ", "КАСТРЫЧНIКА", "ЛIСТАПАДА", "СЬНЕЖНЯ"],
    en: ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]
}

const days = {
   ru: ["ПОНЕДЕЛЬНИК", "ВТОРНИК", "СРЕДА", "ЧЕТВЕРГ", "ПЯТНИЦА", "СУББОТА", "ВОСКРЕСЕНЬЕ"],
   en: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
}

export const toTwoDigits = (num) => num < 10 ? "0" + num.toString() : num.toString()

const isTime = ( originalStr='' ) => {
    const str = originalStr.toString().trim();

    if (!str || str.length !== 5 || str.indexOf(':') === -1) return false
    let [hours, minutes] = str.split(':')

    if (isNaN(hours) || isNaN(minutes)) return false

    let hoursValue = Number(hours)
    let minutesValue = Number(minutes)

    return ((hoursValue >= 0 && hoursValue < 24) && (minutesValue >= 0 && minutesValue < 60))
}

export const getEventTime = (item) => {
    const trimmedTime = item?.time?.trim() || ''
    let time = trimmedTime && isTime(trimmedTime) ? `T${trimmedTime}:00.000Z` : 'T00:00:00.000Z'
    let date = `${item.dateTo || item.date || item.dateFrom || toBrowserDate(new Date())}`
    return new Date(`${date}${time}`)
}

export const getTextDate = (dateToExtract, short=false, small=false, lng=getLanguage()) => {
    const date = new Date(dateToExtract)
    if (date instanceof Date && !isNaN(date)) {
        let monthLabel = short ? months[lng][date.getMonth()].substring(0, 3) : months[lng][date.getMonth()];
        if (small) monthLabel = monthLabel.toLowerCase();
        return `${date.getDate()} ${monthLabel}`
    } else {
        return ''
    }
}

export const getTextDateObject = (dateToExtract, short=false, small=false, lng='ua') => {
    const dateArray = getTextDate(dateToExtract, short, small, lng).split(' ');
    const date = new Date(dateToExtract);
    let dayOfWeek = days[lng][date.getDay()];
    if (short) dayOfWeek = dayOfWeek.substring(0, 3)

    return {
        date: dateArray[0],
        month: dateArray[1],
        year: date.getFullYear(),
        day: dayOfWeek
    }
}

export const getTextTime = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`
}

export const dateToStringDate = (data, isSmall= true, isShort=false, lng='en') => {
    const date = new Date(data);
    return getTextDate(date, isShort, isSmall, lng)
}

export const dateToStringDateTime = (data, isSmall= true, isShort=false) => {
    const date = new Date(data);
    return `${getTextDate(date, isShort, isSmall)}, ${getTextTime(date)}`
}

export const getTextDateTime = (dateToExtract, short= false, small=false, lng='ua') => {
    const date = new Date(dateToExtract);
    return `${getTextDate(date, short, small, lng)}, ${getTextTime(date)}`
}

export const getTextDateNums = (dateToExtract) => {
    const date = new Date(dateToExtract)
    return `${date.getDate()}.${toTwoDigits(date.getMonth() + 1)}`
}

export const toBrowserDate = (dateToExtract) => {
    const date = dateToExtract ? new Date(dateToExtract) : new Date()
    return `${date.getFullYear()}-${toTwoDigits(date.getMonth() + 1)}-${toTwoDigits(date.getDate())}`;
}

export const dateMonthAgo = (dateToExtract) => {
    const date = new Date(dateToExtract);
    return new Date(date.setMonth(date.getMonth() - 1))
}

export const lastMonthStart = () => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    return toBrowserDate(date)
}

export const lastMonthEnd = () => {
    const date = new Date();
    return toBrowserDate(new Date(date.getFullYear(), date.getMonth(), 0));
}

export const isToday = (dateToExtract) => {
    const date = new Date(dateToExtract)
    const today = new Date();
    return (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear())
}

export const isYesterday = (dateToExtract) => {
    const date = new Date(dateToExtract)
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate()-1));
    return (yesterday.getDate() === date.getDate() && yesterday.getMonth() === date.getMonth() && yesterday.getFullYear() === date.getFullYear())
}

export const isInDateRange = (dateToExtract, dateFrom, dateTo) => {
    const date = new Date(dateToExtract)
    const from = new Date(dateFrom);
    const to = new Date(dateTo);
    to.setHours(23);
    to.setMinutes(59);
    return (date >= from && date <= to)
}

export const shiftTime = (dateToExtract, shift=0) => {
    const date = new Date(dateToExtract)
    date.setHours(date.getHours() + shift)
    return date
}
