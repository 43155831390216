import React, {useState} from 'react';
import styles from './about-home.module.scss';
import './about-home.scss';
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import {AboutInfo} from "../about-info/about-info";
import VBox from "../ui/containers/layouts/vbox";
import CreateProjectBlock from "../create-project-block";
import {connect} from "react-redux";
import ReactMarkdown from 'react-markdown'

const AddProject = ({ wording }) => {

    const [rulesAccepted, setRulesAccepted] = useState(false)

    return (
        <Container className={`${styles.about} about`}>
            <Row className={styles.aboutContainer}>
                <Col className={styles.aboutCol}>
                    <AboutInfo
                        subTitle={''}
                        title={wording['add-project-button']}
                        text={''}
                    />
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                {`1. ${wording['footer-heading-rules']}`}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line'}}>
                                    <ReactMarkdown>{wording['page-rules']}</ReactMarkdown>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                {`2. ${wording['footer-heading-policy']}`}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line'}}>
                                    <ReactMarkdown>{wording['page-policy']}</ReactMarkdown>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                {`3. ${wording['footer-heading-stripe-policy']}`}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line'}}>
                                    <ReactMarkdown>{wording['page-stripe-policy']}</ReactMarkdown>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                {`4. ${wording['footer-heading-faq']}`}
                                <FontAwesomeIcon icon={faAngleDown} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{whiteSpace: 'pre-line'}}>
                                    <ReactMarkdown>{wording['page-faq']}</ReactMarkdown>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <VBox style={{marginTop: 20}}>
                    <label style={{display: "flex", alignItems: 'center', gap: 10}}>
                            <input style={{width: 20, height: 20}}
                                   type="checkbox"
                                   checked={rulesAccepted}
                                   onChange={() => setRulesAccepted(!rulesAccepted)}
                            />
                            <strong>{wording['policy-confirm']}</strong>
                        </label>
                        {rulesAccepted && <CreateProjectBlock/>}
                    </VBox>
                    <div style={{height: 100}}/>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = ({  projects: { projects }, system: { wording } }) => {
    return { projects, wording };
};

export default connect(mapStateToProps)(AddProject);
