import React from 'react';
import styles from './event-info.module.scss';
import './event-info-carousel.scss';
import {Link, useParams} from "react-router-dom";
import {faCalendarDays, faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContentInfo} from "../../components/content-info/content-info";
import {EventDate} from "../../components/event-date/event-date";
import {connect} from "react-redux";
import {getTextDate} from "../../helper/dates";
import ReactMarkdown from "react-markdown";
import {ActionButton} from "../../components/action-button/action-button";

const EventInfo = ({ events }) => {
    const params = useParams();

    const getCurrentEventsData = () => {
        let currentEvent;
        events.forEach((ev) => {
            if (ev.id === params.id) currentEvent = ev;
        })
        return currentEvent;
    }


    const {description, heading, time, date} = getCurrentEventsData();
    const year = new Date(date).getFullYear()

    return (
        <div className={`${styles.eventInfoContainer} event-info`}>
            <ContentInfo>
                <div className={styles.flexBetween}>
                    <Link to={'/'}><ActionButton icon={false}>Назад</ActionButton></Link>
                    {date && <EventDate eventDate={getCurrentEventsData().date} />}
                </div>

                <div className={styles.eventDetails}>
                    <h2 className={styles.title}>
                        {heading}
                    </h2>
                    <div className={styles.text}>
                        <ReactMarkdown className={styles.preLine}>{description}</ReactMarkdown>
                    </div>
                </div>
                {date && <ul className={styles.eventInfo}>
                    <li>
                        <FontAwesomeIcon icon={faCalendarDays}/>
                        {getTextDate(date)}, {year}
                    </li>
                    {time && <li>
                        <FontAwesomeIcon icon={faClock}/>
                        {time}
                    </li>}
                </ul>}
            </ContentInfo>
        </div>
    )
}

export default connect()(EventInfo);
