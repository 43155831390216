import React from 'react';
import styles from './fact.module.scss';
import './fact-circle.scss';
import {CircleProgress} from "../circle-progress/circle-progress";

export const Fact = ({percentage = 0, title = '', className}) => {
    return (
        <div className={`${styles.fact}`}>
            <CircleProgress
                percentage={percentage}
                duration={3000}
                strokeWidth={2}
                bar={false}
                className={`${className} info-circle`}
            />
            <h3>{title}</h3>
        </div>
    )
}
