import React from 'react';
import HBox from "../ui/containers/layouts/hbox";

export const Switcher = ({ pages, page, setPage }) => {

    return (
        <HBox style={{ borderRadius: 50, backgroundColor: '#FFF', padding: '10px 10px'}} className={'shadow'}>
            <>
                {pages.map((item, index) => <div onClick={() => setPage(item)}
                        key={index}
                        style={{
                            cursor: 'pointer',
                            borderRadius: 50,
                            padding: '10px 20px',
                            backgroundColor: page === item ? '#23a884' : '#FFF',
                        }}>
                        {page === item ? <strong style={{color: '#FFF'}}>{item}</strong> : <span>{item}</span>}
                </div>
                )}
            </>
        </HBox>
    )
}
