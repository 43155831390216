import React from 'react';
import styles from './header.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyCheckDollar} from "@fortawesome/free-solid-svg-icons";
import {faTelegram, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {CHNavbar} from "../navbar/navbar";
import gronkaLogo from '../../assets/logo/gronka_black.svg'
import {Link} from "react-router-dom";
import {socialInstagramUrl, socialTelegramUrl, supportUrl} from "../../configs/keys";
import {connect} from "react-redux";
import {updateLanguage} from "../../redux/actions/actions-user";

const Header = ({ language, wording, updateLanguage, walletAmount }) => {
    return (
        <header className={styles.header}>
            <div className={styles.topBar}>
                <Container className={styles.topBarContainer} fluid="md">
                    <Row>
                        <Col lg={7} md={7} sm className={styles.contactContainer}>
                            <ul className={styles.topBarList}>
                                <li>
                                    <Link to={'/'}><img src={gronkaLogo} height={80} alt={'logo'} /></Link>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={5} md={5} className={styles.donateContainer}>
                            <select style={{border: 'unset', marginRight: 10}} value={language} onChange={e => updateLanguage(e.target.value)}>
                                <option value={'by'}>БЕ</option>
                                <option value={'ru'}>РУ</option>
                            </select>
                            <div className={styles.socialFollowDonate}>
                                <div className={styles.socialFollow}>
                                    <ul className={styles.socialLinks}>
                                        <li><a href={socialInstagramUrl} target={'_blank'} rel={'noreferrer noopener'}>
                                            <FontAwesomeIcon icon={faSquareInstagram}/>
                                        </a></li>
                                        <li><a href={socialTelegramUrl} target={'_blank'} rel={'noreferrer noopener'}>
                                            <FontAwesomeIcon icon={faTelegram}/>
                                        </a></li>
                                        <li><a href={`mailto:${supportUrl}`} target={'_blank'} rel={'noreferrer noopener'}>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </a></li>
                                    </ul>
                                </div>
                                <div className={styles.donate}>
                                    <Link to={'/add'}>
                                        <FontAwesomeIcon icon={faMoneyCheckDollar}/>
                                        {wording['add-project-button']}
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CHNavbar wording={wording} walletAmount={walletAmount} />
        </header>
    )
}

const mapStateToProps = ({  system: { language, wording }, user: { walletAmount }  }) => {
    return { language, wording, walletAmount };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateLanguage: updateLanguage(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
