import React, {useEffect, useState} from "react";
import './App.scss';
import Header from "./components/header/header";
import {Route, Routes} from "react-router-dom";
import Main from "./pages/main/main";
import Footer from "./components/footer/footer";
import EventInfo from "./pages/event-info/event-info";
import Events from "./pages/events/events";
import CausesInfo from "./pages/causes-info/causes-info";
//import topEvents from "./data/top-events-mock.json";
import Causes from "./pages/causes/causes";
//import {Contact} from "./pages/contact/contact";
import {owlCarouselNavReset} from "./helper/owlCarouselNavReset";
import {connect} from "react-redux";
import {loadProjects} from "./redux/actions/actions-projects";
import {getSystemData} from "./redux/actions/actions-user";
import AddProject from "./components/add-project/add-project";
import ContentPage from "./pages/content-page/content-page";
import Creators from "./pages/creators/creators";
import APIService from "./services/api-service/api-service";
import ProfileInfo from "./components/profile-info/profile-info";

const apiService = new APIService()

function App({ projects, wording, getSystemData, events, creators, news }) {

    const [warning, setWarning] = useState(false)

    const validateInitialSettings = async () => {
        try {

            const result = await apiService.getUserIPLocation()
            if (result?.country) {
                if (result?.country?.toLowerCase() === 'by') {
                    setWarning(true)
                } else {
                    getSystemData()
                }
            } else {
                getSystemData()
            }

        } catch (e) {
            getSystemData()
        }
    }

    useEffect(() => {
        validateInitialSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const invoiceId = new URLSearchParams(window.location.search).get(
        "invoiceId"
    );

    if (warning) return <div style={{ padding: 20 }}>Калі ласка, уключыце спачатку VPN!</div>

    if (!projects.length || !Object.keys(wording).length) return null;


    owlCarouselNavReset();


  return (
    <div className="App">
        <Header/>
        <Routes>
            <Route path={'/'} element={
                <Main />
            }></Route>
            <Route path={'/event/:id'} element={
                <EventInfo events={events} />
            }></Route>
            <Route path={'/creator/:id'} element={
                <EventInfo events={creators} />
            }></Route>
            <Route path={'/news/:id'} element={
                <EventInfo events={news} />
            }></Route>
            <Route path={'/events'} element={
                <Events  />
            }></Route>
            <Route path={'/add'} element={
                <AddProject />
            }></Route>
            <Route path={'/causes/:id'} element={
                <CausesInfo clientUrlSecret={clientSecret || invoiceId} causes={projects} />
            }></Route>
            <Route path={'/causes'} element={
                <Causes causes={projects} />
            }></Route>
            <Route path={'/categories/:id'} element={
                <Causes causes={projects} />
            }></Route>
            <Route path={'/profile'} element={
                <ProfileInfo />
            }></Route>

            <Route path={'/about'} element={
                <ContentPage heading={wording['footer-heading-about']} content={wording['page-about']} />
            }></Route>
            <Route path={'/faq'} element={
                <ContentPage heading={wording['footer-heading-faq']} content={wording['page-faq']} />
            }></Route>
            <Route path={'/memo'} element={
                <ContentPage heading={wording['footer-heading-memorandum']} content={wording['page-memorandum']} />
            }></Route>
            <Route path={'/press'} element={
                <ContentPage heading={wording['footer-heading-press']} content={wording['page-press']} />
            }></Route>
            <Route path={'/help-center'} element={
                <ContentPage heading={wording['footer-heading-help-center']} content={wording['page-help-center']} />
            }></Route>
            <Route path={'/how'} element={
                <ContentPage heading={wording['footer-heading-howwe']} content={wording['page-howwe']} />
            }></Route>
            <Route path={'/rules'} element={
                <ContentPage heading={wording['footer-heading-rules']} content={wording['page-rules']} />
            }></Route>
            <Route path={'/creators'} element={
                <Creators />
            }></Route>
            <Route path={'/faq'} element={
                <ContentPage heading={wording['footer-heading-faq']} content={wording['page-faq']} />
            }></Route>
            <Route path={'/usage'} element={
                <ContentPage heading={wording['footer-heading-usage']} content={wording['page-usage']} />
            }></Route>
            <Route path={'/policy'} element={
                <ContentPage heading={wording['footer-heading-policy']} content={wording['page-policy']} />
            }></Route>
            <Route path={'/stripe-policy'} element={
                <ContentPage heading={wording['footer-heading-stripe-policy']} content={wording['page-stripe-policy']} />
            }></Route>
        </Routes>
        <Footer />
    </div>
  );
}

const mapStateToProps = ({  projects: { projects }, system: { wording, events, creators, news } }) => {
    return { projects, wording, events, creators, news };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadProjects: loadProjects(dispatch),
        getSystemData: getSystemData(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
