import React from 'react';
import styles from './causes.module.scss';
import {connect} from "react-redux";
import {ContentInfo} from "../../components/content-info/content-info";
import {Event} from "../../components/event/event";

const Creators = ({creators, wording, projects}) => {


    return (
        <div className={`${styles.causes}`}>
            <ContentInfo asideProjects={projects}>
                <h1 style={{marginBottom: 50}}>{wording['creators-heading']}</h1>
                <div>
                    {creators.map((creator, index) => (
                        <Event eventLink={'creator'} eventData={creator} key={index} />
                    ))}
                </div>
            </ContentInfo>
        </div>
    )
}

const mapStateToProps = ({  system: { creators, wording }, projects: { projects } }) => {
    return { creators, wording, projects };
};

export default connect(mapStateToProps)(Creators);
