import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import ErrorBoundry from "./components/error-boundry";
import {HashRouter as Router, useLocation} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ErrorBoundry>
            <Router>
                <ScrollToTop />
                <App />
            </Router>
        </ErrorBoundry>
    </Provider>
);
