import APIService from "../../services/api-service/api-service";

import {
    createAction, PROJECTS_LOADED,
} from "../actions-factory";


const apiService = new APIService();


export const loadProjects = (dispatch) => async (language='by') => {
    apiService.getCrowdfundingProjects(language)
        .then(async (data) => {
            dispatch(createAction(PROJECTS_LOADED, data))
        })
        .catch(async (err) => {
            console.log(err)
        });
}

export const updateProjects = (dispatch) => async () => {
    apiService.updateCrowdfundingProjects()
        .then(async (data) => {
            dispatch(createAction(PROJECTS_LOADED, data))
        })
        .catch(async (err) => {
            console.log(err)
        });
}
