import React from 'react';
import styles from './category-card.module.scss';
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";

export const CategoryCard = ({name = '', text = '', id = '', icon = '', wording}) => {
    return (
        <Col className={`${styles.card}`} md={4} sm={6}>
            <Link to={`/categories/${id}`} className={styles.details}><div className={styles.grid}>
                {icon && <div className="icon">
                    {icon}
                </div>}
                <div className={styles.info}>
                    <h3 style={{height: 50}}>{name}</h3>
                    <p>{text}</p>
                    <div className={styles.details}>{wording['see-heading']}</div>
                </div>
            </div></Link>
        </Col>
    )
}
