import {
    PROFILE_LOADED,
    USER_ERROR,
    USER_LOADED,
} from "../actions-factory";

const updateUser = (state, action) => {

    if (state === undefined) {
        return {
            token: '',
            userId: '',
            userEmail: '',
            profile: {},
            walletAmount: 0
        };
    }

    switch (action.type) {

        case USER_LOADED: {
            return {
                ...state.user,
                token: action.payload.token,
                userId: action.payload.userId,
                userEmail: action.payload.email,
            };
        }

        case USER_ERROR: {
            return {
                ...state.user,
                token: '',
                userId: '',
                userEmail: '',
            };
        }

        case PROFILE_LOADED: {
            return {
                ...state.user,
                profile: action.payload || {},
                walletAmount: action.payload?.walletSum || 0
            };
        }

        default:
            return state.user;
    }
};

export default updateUser;
