import React from 'react';
import styles from './action-button.module.scss';
import './action-button.scss';
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ActionButton = ({children, onClick, link = '', icon = true, className, disabled, mini, secondary, border}) => {
    return (
        <>
            {link
                ?
                <a
                    href={link}
                    target={'_blank'}
                    rel={'noreferrer noopener'}
                    className={`${styles.button} ${className ? className : ''}`}
                >
                    <span>{children}</span>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </a>
                :
                <button
                    disabled={disabled}
                    className={`${styles.button} ${className ? className : ''} ${mini ? styles.mini : ''} ${secondary ? styles.secondary : ''} ${border ? styles.border : ''}`}
                    onClick={onClick}
                >
                    <span>{children}</span>
                    {icon && <FontAwesomeIcon icon={faAngleRight}/>}
                </button>
            }
        </>
    )
}
